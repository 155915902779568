var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selection-filter-item"},[_c('div',{staticClass:"selection-form-control"},[_c('VSelect',{class:'filter-field-select',attrs:{"options":_vm.fields,"set-default-option":false,"required":true},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_c('p',{staticClass:"selected-option",class:{'text-gray': _vm.filterNotUsed}},[_vm._v(" "+_vm._s(selected.label))])]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('p',{class:{'text-gray': _vm.fieldNotUsed(option.code)}},[_vm._v(_vm._s(option.label))])]}}]),model:{value:(_vm.field),callback:function ($$v) {_vm.field=$$v},expression:"field"}}),_c('VSelect',{class:'filter-operator-select',attrs:{"options":_vm.operatorOptions,"set-default-option":false,"required":true,"min-width":_vm.widthCalculate,"max-width":_vm.widthCalculate,"appendToBody":true},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"flex-start","max-height":"28px"}},[_c('p',{staticClass:"selected-option",class:{'text-gray': _vm.filterNotUsed}},[_vm._v(" "+_vm._s(selected.label))])])]}}]),model:{value:(_vm.operator),callback:function ($$v) {_vm.operator=$$v},expression:"operator"}}),(_vm.filterValueInputTypeIsText)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.textValue),expression:"textValue"}],staticClass:"form-control input-type-1 text-input filter-input-value",class:{'text-gray': _vm.filterNotUsed},attrs:{"type":"text"},domProps:{"value":(_vm.textValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.textValue=$event.target.value}}}):_vm._e(),(!_vm.filterValueInputTypeIsText)?_c('VSelect',{class:'filter-input-value',attrs:{"set-default-option":false,"required":true,"options":_vm.valueOptions},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var selected = ref.selected;
return [_c('p',{staticClass:"selected-option",class:{'text-gray': _vm.filterNotUsed}},[_vm._v(" "+_vm._s(selected.label))])]}}],null,false,3084943667),model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}}):_vm._e(),_c('button',{staticClass:"btn btn-custom btn-sm icn-left delete-filter-btn",on:{"click":_vm.deleteFilter}},[_c('i',{staticClass:"fa-light fa-trash-can"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }