

























































































































































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  ENTITY_TYPE_AD,
  ENTITY_TYPE_ADSET,
  ENTITY_TYPE_CAMPAIGN,
  ENTITY_TYPE_EXTENSION,
  ENTITY_TYPE_KEYWORD,
  ENTITY_TYPE_SEARCH_TERM,
  GOOGLE_SERVICE,
  LINKEDIN_SERVICE,
  SELECTION_METHODS,
  TWITTER_SERVICE
} from '@/constants/FbAutomatedRule'
import SelectSpecificItems from '@/components/strategies/modal/components/SelectSpecificItems.vue'
import SelectionsFilters from '@/components/strategies/modal/components/SelectionsFilters.vue'
import { getEstimatedMatchCounts, getSelectedName } from '@/services/AutomatedRules/SelectedEntities'
import { fieldNotUsable } from '@/services/AutomatedRules/SelectionFilters'

export default Vue.extend({
  name: 'SelectEntitiesModalFacebook',
  props: {
    rule: {
      required: true,
      type: Object
    }
  },
  components: { SelectSpecificItems, SelectionsFilters },
  data () {
    return {
      entityType: ENTITY_TYPE_CAMPAIGN,
      selectionMethod: SELECTION_METHODS.SPECIFIC_ITEMS,
      entityLevel: {
        code: ENTITY_TYPE_CAMPAIGN,
        label: 'Campaigns'
      },
      selectionMethodOptions: [
        {
          code: SELECTION_METHODS.SPECIFIC_ITEMS,
          label: 'Select specific items'
        },
        {
          code: SELECTION_METHODS.SELECTIONS_FILTER,
          label: 'Selections filter'
        }
      ],
      selectionFilters: []
    }
  },
  created () {
    if (this.rule.entity_type) {
      this.selectEntity(this.entitiesOptions.find(opt => opt.code === this.rule.entity_type))
    } else {
      this.selectEntity(this.entitiesOptions[0])
    }
    if (this.rule.selection_method) {
      this.selectionMethod = this.rule.selection_method
    }
    if (this.rule.selection_filters) {
      this.selectionFilters = this.rule.selection_filters
    }
  },
  computed: {
    ...mapState({
      errors: state => (state as any).rules.errors
    }),

    ...mapGetters({
      entitiesType: 'rules/entitiesStatus',
      currentService: 'adService/getService',
      getSelectedEntities: 'rules/getSelectedEntities'
    }),

    saveButtonDisabled () {
      return this.selectionMethod === SELECTION_METHODS.SELECTIONS_FILTER &&
        (!this.selectionFilters.length ||
          !this.selectionFilters.some((filter) => {
            return filter.value && !fieldNotUsable(filter.field, this.entityType)
          }))
    },
    entitiesOptions () {
      switch (this.currentService) {
        case LINKEDIN_SERVICE:
          return [
            {
              code: ENTITY_TYPE_CAMPAIGN,
              label: 'Campaign groups'
            },
            {
              code: ENTITY_TYPE_ADSET,
              label: 'Campaigns'
            },
            {
              code: ENTITY_TYPE_AD,
              label: 'Ads'
            }
          ]
        case TWITTER_SERVICE:
          return [
            {
              code: ENTITY_TYPE_CAMPAIGN,
              label: 'Campaigns'
            },
            {
              code: ENTITY_TYPE_ADSET,
              label: 'Ad Group'
            },
            {
              code: ENTITY_TYPE_AD,
              label: 'Ads'
            }
          ]
        case GOOGLE_SERVICE:
          return [
            {
              code: ENTITY_TYPE_CAMPAIGN,
              label: 'Campaigns'
            },
            {
              code: ENTITY_TYPE_ADSET,
              label: 'Ad sets'
            },
            {
              code: ENTITY_TYPE_EXTENSION,
              label: 'Extensions'
            },
            {
              code: ENTITY_TYPE_AD,
              label: 'Ads'
            },
            {
              code: ENTITY_TYPE_SEARCH_TERM,
              label: 'Search Terms'
            },
            {
              code: ENTITY_TYPE_KEYWORD,
              label: 'Keywords'
            }
          ]
        default:
          return [
            {
              code: ENTITY_TYPE_CAMPAIGN,
              label: 'Campaigns'
            },
            {
              code: ENTITY_TYPE_ADSET,
              label: 'Ad sets'
            },
            {
              code: ENTITY_TYPE_AD,
              label: 'Ads'
            }
          ]
      }
    },
    selectionMethodSpecificItems () {
      return this.selectionMethod === SELECTION_METHODS.SPECIFIC_ITEMS
    },
    selectedCampaigns () {
      return this.getSelectedEntities.campaigns
    },
    selectedAdSets () {
      return this.getSelectedEntities.ad_sets
    },
    selectedAds () {
      return this.getSelectedEntities.ads
    },
    selectedExtensions () {
      return this.getSelectedEntities.extensions
    },
    selectedSearchTerms () {
      return this.getSelectedEntities.search_terms
    },
    selectedKeywords () {
      return this.getSelectedEntities.keywords
    },
    estimatedMatchCounts () {
      return getEstimatedMatchCounts(this.selectionMethod, this.currentService)
    },
    modalTitle () {
      if (this.selectionMethod === SELECTION_METHODS.SPECIFIC_ITEMS) {
        return 'Select specific items'
      } else {
        return 'Selections filter'
      }
    }
  },
  methods: {
    ...mapMutations('rules', ['setRuleProp']),
    ...mapActions('rules', [
      'resetExecutionSpecIfNeed',
      'setSelectedEntitiesPack',
      'clearSelectedEntities',
      'setRuleEntityType',
      'requestSelectionFiltersEstimatedMatch'
    ]),
    // выбор в главном select
    selectEntity (v) {
      this.entityLevel = v
      this.clearEntities()
      this.entityType = this.entityLevel.code
      if (this.selectionMethod === SELECTION_METHODS.SELECTIONS_FILTER) {
        this.requestSelectionFiltersEstimatedMatch({ ad_account_id: this.rule.ad_account_id, entity_type: this.entityType, selection_filters: this.selectionFilters })
      }
    },

    close_modal () {
      this.searchCampaign = ''
      if (this.rule.selection_method === SELECTION_METHODS.SELECTIONS_FILTER) {
        this.requestSelectionFiltersEstimatedMatch({ ad_account_id: this.rule.ad_account_id, entity_type: this.rule.entity_type, selection_filters: this.rule.selection_filters })
      }
      this.$attrs.on_close('select-entities')
    },

    save () {
      if (this.entityLevel && this.rule.entity_type !== this.entityLevel.code) {
        this.resetExecutionSpecIfNeed({ id: this.rule.id, entityType: this.entityLevel.code })
      }
      this.setRuleProp({
        id: this.rule.id,
        type: 'selection_method',
        value: this.selectionMethod
      })
      this.setRuleEntityType({ id: this.rule.id, entityType: this.entityType, service: this.currentService })
      if (this.selectionMethod === SELECTION_METHODS.SELECTIONS_FILTER) {
        this.setRuleProp({
          id: this.rule.id,
          type: 'selection_filters',
          value: this.selectionFilters
        })
      } else {
        let value: Record<string, any> = {
          campaigns: this.selectedCampaigns,
          ad_sets: this.selectedAdSets,
          ads: this.selectedAds
        }
        if (this.currentService === GOOGLE_SERVICE) {
          value = {
            ...value,
            extensions: this.selectedExtensions,
            search_terms: this.selectedSearchTerms,
            keywords: this.selectedKeywords
          }
        }
        this.setRuleProp({
          id: this.rule.id,
          type: 'entities',
          value: value
        })
      }
      if (
        this.errors['entities.ads'] &&
        (this.selectedCampaigns.length ||
          this.selectedAdsets.length ||
          this.selectedAds.length)
      ) {
        this.$store.dispatch('rules/removeError', 'entities.ads')
      }

      this.close_modal()
    },

    clearCampaigns () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_CAMPAIGN, ids: [] })
    },
    clearAdSets () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_ADSET, ids: [] })
    },
    clearExtensions () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_EXTENSION, ids: [] })
    },
    clearKeywords () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_KEYWORD, ids: [] })
    },
    clearAds () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_AD, ids: [] })
    },
    clearEntities () {
      this.clearSelectedEntities()
    },
    clearSearchTerms () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_SEARCH_TERM, ids: [] })
    },
    updateFilters (filters) {
      this.selectionFilters = filters
      this.requestSelectionFiltersEstimatedMatch({ ad_account_id: this.rule.ad_account_id, entity_type: this.entityType, selection_filters: this.selectionFilters })
    },
    getSelectedEntityName (type: string) {
      return getSelectedName(type, this.currentService)
    }
  }
})
