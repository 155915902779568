






























































import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import {
  SELECTION_FILTER_AD_DELIVERY,
  SELECTION_FILTER_AD_STATUS,
  SELECTION_FILTER_ADSET_DELIVERY,
  SELECTION_FILTER_ADSET_STATUS,
  SELECTION_FILTER_CAMPAIGN_DELIVERY,
  SELECTION_FILTER_CAMPAIGN_OBJECTIVE,
  SELECTION_FILTER_CAMPAIGN_STATUS,
  SELECTION_FILTER_FIELD,
  SELECTION_FILTER_OPERATOR
} from '@/models/Rules/IRule'
import { fieldNotUsable } from '@/services/AutomatedRules/SelectionFilters'

export default Vue.extend({
  name: 'SelectionFilter',
  props: {
    filter: {
      required: true
    },
    entity_type: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      fields: [
        {
          code: SELECTION_FILTER_FIELD.CAMPAIGN_NAME,
          label: 'Campaign name'
        },
        {
          code: SELECTION_FILTER_FIELD.AD_SET_NAME,
          label: 'Ad set name'
        },
        {
          code: SELECTION_FILTER_FIELD.AD_NAME,
          label: 'Ad name'
        },
        {
          code: SELECTION_FILTER_FIELD.CAMPAIGN_STATUS,
          label: 'Campaign status'
        },
        {
          code: SELECTION_FILTER_FIELD.AD_SET_STATUS,
          label: 'Ad set status'
        },
        {
          code: SELECTION_FILTER_FIELD.AD_STATUS,
          label: 'Ad status'
        },
        {
          code: SELECTION_FILTER_FIELD.CAMPAIGN_DELIVERY,
          label: 'Campaign delivery'
        },
        {
          code: SELECTION_FILTER_FIELD.AD_SET_DELIVERY,
          label: 'Ad set delivery'
        },
        {
          code: SELECTION_FILTER_FIELD.AD_DELIVERY,
          label: 'Ad delivery'
        },
        {
          code: SELECTION_FILTER_FIELD.CAMPAIGN_OBJECTIVE,
          label: 'Campaign objective'
        }
      ],
      selectOperators: [
        {
          code: SELECTION_FILTER_OPERATOR.IS,
          label: 'is'
        },
        {
          code: SELECTION_FILTER_OPERATOR.IS_NOT,
          label: 'is not'
        }
      ],
      textOperators: [
        {
          code: SELECTION_FILTER_OPERATOR.CONTAINS,
          label: 'contains'
        },
        {
          code: SELECTION_FILTER_OPERATOR.NOT_CONTAINS,
          label: 'does not contain'
        }
      ],
      field: {
        code: SELECTION_FILTER_FIELD.CAMPAIGN_NAME,
        label: 'Campaign name'
      },
      operator: {
        code: SELECTION_FILTER_OPERATOR.CONTAINS,
        label: 'contains'
      },
      selectValue: null,
      textValue: ''
    }
  },
  components: { VSelect },
  created () {
    this.field = this.fields.find(f => f.code === this.filter.field)
    this.operator = this.operatorOptions.find(op => op.code === this.filter.operator)
    if (this.filterValueInputTypeIsText) {
      this.textValue = this.filter.value
    } else {
      this.selectValue = this.valueOptions.find(option => option.code === this.filter.value)
    }
    this.applyWatchers()
  },
  computed: {
    filterNotUsed () {
      return this.fieldNotUsed(this.field.code)
    },
    filterValueInputTypeIsText () {
      return this.operator.code === SELECTION_FILTER_OPERATOR.CONTAINS || this.operator.code === SELECTION_FILTER_OPERATOR.NOT_CONTAINS
    },
    valueOptions () {
      switch (this.field.code) {
        case SELECTION_FILTER_FIELD.CAMPAIGN_STATUS:
          return [
            {
              code: SELECTION_FILTER_CAMPAIGN_STATUS.ACTIVE,
              label: 'Active'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_STATUS.PAUSED,
              label: 'Paused'
            }
          ]
        case SELECTION_FILTER_FIELD.AD_SET_STATUS:
          return [
            {
              code: SELECTION_FILTER_ADSET_STATUS.ACTIVE,
              label: 'Active'
            },
            {
              code: SELECTION_FILTER_ADSET_STATUS.PAUSED,
              label: 'Paused'
            }
          ]
        case SELECTION_FILTER_FIELD.AD_STATUS:
          return [
            {
              code: SELECTION_FILTER_AD_STATUS.ACTIVE,
              label: 'Active'
            },
            {
              code: SELECTION_FILTER_AD_STATUS.PAUSED,
              label: 'Paused'
            }
          ]
        case SELECTION_FILTER_FIELD.CAMPAIGN_DELIVERY:
          return [
            {
              code: SELECTION_FILTER_CAMPAIGN_DELIVERY.ACTIVE,
              label: 'Active'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_DELIVERY.INACTIVE,
              label: 'Inactive'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_DELIVERY.SCHEDULED,
              label: 'Scheduled'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_DELIVERY.COMPLETED,
              label: 'Completed'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_DELIVERY.NOT_DELIVERING,
              label: 'Not Delivering'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_DELIVERY.REJECTED,
              label: 'Rejected'
            }
          ]
        case SELECTION_FILTER_FIELD.AD_SET_DELIVERY:
          return [
            {
              code: SELECTION_FILTER_ADSET_DELIVERY.ACTIVE,
              label: 'Active'
            },
            {
              code: SELECTION_FILTER_ADSET_DELIVERY.INACTIVE,
              label: 'Inactive'
            },
            {
              code: SELECTION_FILTER_ADSET_DELIVERY.SCHEDULED,
              label: 'Scheduled'
            },
            {
              code: SELECTION_FILTER_ADSET_DELIVERY.COMPLETED,
              label: 'Completed'
            },
            {
              code: SELECTION_FILTER_ADSET_DELIVERY.NOT_DELIVERING,
              label: 'Not Delivering'
            }
          ]
        case SELECTION_FILTER_FIELD.AD_DELIVERY:
          return [
            {
              code: SELECTION_FILTER_AD_DELIVERY.ACTIVE,
              label: 'Active'
            },
            {
              code: SELECTION_FILTER_AD_DELIVERY.PENDING_REVIEW,
              label: 'Pending review'
            },
            {
              code: SELECTION_FILTER_AD_DELIVERY.INACTIVE,
              label: 'Inactive'
            },
            {
              code: SELECTION_FILTER_AD_DELIVERY.SCHEDULED,
              label: 'Scheduled'
            },
            {
              code: SELECTION_FILTER_AD_DELIVERY.COMPLETED,
              label: 'Completed'
            },
            {
              code: SELECTION_FILTER_AD_DELIVERY.NOT_DELIVERING,
              label: 'Not Delivering'
            },
            {
              code: SELECTION_FILTER_AD_DELIVERY.CAMPAIGN_PAUSED,
              label: 'Campaign paused'
            },
            {
              code: SELECTION_FILTER_AD_DELIVERY.ADSET_PAUSED,
              label: 'Ad set paused'
            },
            {
              code: SELECTION_FILTER_AD_DELIVERY.DISAPPROVED,
              label: 'Not approved'
            },
            {
              code: SELECTION_FILTER_AD_DELIVERY.REJECTED,
              label: 'Rejected'
            }
          ]
        case SELECTION_FILTER_FIELD.CAMPAIGN_OBJECTIVE:
          return [
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.APP_INSTALLS,
              label: 'App installs'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.CONVERSIONS,
              label: 'Conversions'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.REACH,
              label: 'Reach'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.LOCAL_AWARENESS,
              label: 'Awareness'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.CATALOG_SALES,
              label: 'Catalog sales'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.VIDEO_VIEWS,
              label: 'Video views'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.LINK_CLICKS,
              label: 'Traffic'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.OUTCOME_SALES,
              label: 'Sales'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.POST_ENGAGEMENT,
              label: 'Post engagement'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.PAGE_LIKES,
              label: 'Page likes'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.OFFER_CLAIMS,
              label: 'Offer claims'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.MESSAGES,
              label: 'Messages'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.OUTCOME_LEADS,
              label: 'Leads'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.LEAD_GENERATION,
              label: 'Lead generation'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.EVENT_RESPONSES,
              label: 'Event responses'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.STORE_TRAFFIC,
              label: 'Store traffic'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.BRAND_AWARENESS,
              label: 'Brand awareness'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.OUTCOME_AWARENESS,
              label: 'Outcome awareness'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.OUTCOME_ENGAGEMENT,
              label: 'Outcome engagement'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.OUTCOME_APP_PROMOTION,
              label: 'Outcome app promotion'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.PRODUCT_CATALOG_SALES,
              label: 'Product catalog sales'
            },
            {
              code: SELECTION_FILTER_CAMPAIGN_OBJECTIVE.STORE_VISITS,
              label: 'Store visits'
            }
          ]
        default:
          return []
      }
    },
    operatorOptions () {
      switch (this.field.code) {
        case SELECTION_FILTER_FIELD.CAMPAIGN_STATUS:
        case SELECTION_FILTER_FIELD.AD_SET_STATUS:
        case SELECTION_FILTER_FIELD.AD_STATUS:
        case SELECTION_FILTER_FIELD.CAMPAIGN_DELIVERY:
        case SELECTION_FILTER_FIELD.AD_SET_DELIVERY:
        case SELECTION_FILTER_FIELD.AD_DELIVERY:
        case SELECTION_FILTER_FIELD.CAMPAIGN_OBJECTIVE:
          return this.selectOperators
        default:
          return this.textOperators
      }
    },
    widthCalculate () {
      let length = 0
      this.operatorOptions.forEach((el) => {
        if (length < el.label.length) length = el.label.length
      })
      return length * 6 + 50
    }
  },
  methods: {
    applyWatchers () {
      this.$watch('field', function (newValue) {
        this.$emit('update_filter', { ...this.filter, field: newValue.code })
        this.operator = null // чтобы сработал watch на operator и обновил value
        this.operator = this.operatorOptions[0]
      })
      this.$watch('operator', function (newValue) {
        const data = {
          operator: newValue.code,
          value: this.filter.value
        }
        if (newValue.code === SELECTION_FILTER_OPERATOR.CONTAINS || newValue.code === SELECTION_FILTER_OPERATOR.NOT_CONTAINS) {
          data.value = this.textValue
        } else {
          if (this.selectValue && this.valueOptions.find(option => option.code === this.selectValue.code)) {
            data.value = this.selectValue.code
          } else {
            data.value = this.valueOptions[0].code
            this.selectValue = this.valueOptions[0]
          }
        }
        this.$emit('update_filter', { ...this.filter, ...data })
      })
      this.$watch('selectValue', function (newValue) {
        this.$emit('update_filter', { ...this.filter, value: newValue.code })
      })
      this.$watch('textValue', function (newValue) {
        this.$emit('update_filter', { ...this.filter, value: newValue })
      })
    },
    deleteFilter () {
      this.$emit('delete_filter')
    },
    fieldNotUsed (field: SELECTION_FILTER_FIELD) {
      return fieldNotUsable(field, this.entity_type)
    }
  }
})
