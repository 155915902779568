




























































































import Modal from '../../Modal.vue'
import VSelect from '../../../components/BaseComponents/VSelect/VSelect.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { messages } from '@/i18n/messages'
import { required } from 'vuelidate/lib/validators'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import Vue from 'vue'
export default Vue.extend({
  name: 'SavingRuleModal',
  components: { Modal, VSelect, BaseInputVuelidate },
  props: ['rule', 'folder'],
  data () {
    return {
      new_name: '',
      new_description: '',
      folder_name: '',
      folder_description: '',
      showError: false,
      createFolder: false,
      openFolderSection: false,
      selectedFolder: '',
      saveProcessing: false
    }
  },
  validations: {
    new_name: {
      required
    },
    folder_name: {
      required
    }
  },
  i18n: {
    messages
  },
  watch: {
    new_name (v) {
      this.setRuleProp({
        id: this.rule.id,
        type: 'name',
        value: v
      })
    },
    openFolderSection (v) {
      if (!v) {
        this.setRuleProp({
          id: this.rule.id,
          type: 'folder_id',
          value: null
        })
      }
    },
    selectedFolder (v) {
      this.setRuleProp({
        id: this.rule.id,
        type: 'folder_id',
        value: v.id
      })
    }
  },
  methods: {
    toggleNeedCreateFolder () {
      this.createFolder = !this.createFolder
    },
    ...mapMutations('rulesFolders', ['setFolderStateProp']),
    ...mapMutations('rules', ['setRuleProp', 'removeError', 'removeErr', 'setProcessing']),
    closeModal () {
      this.$modal.hide('save-rule-modal')
    },
    toggleOpenFolderSection () {
      this.openFolderSection = !this.openFolderSection
      if (this.openFolderSection && !this.createFolder) {
        if (this.rule.folder_id) {
          this.selectedFolder = this.getFolderById(this.rule.folder_id)
        } else {
          this.selectedFolder = this.getAllFolders.data[0]
        }
      }
    },
    changeName (acc) {
      this.new_name = acc.value
      this.removeErr({ key: 'name' })
    },
    saveRule () {
      this.setProcessing({ processing: true })
      if (this.createFolder) {
        const data = {
          name: this.new_name,
          description: this.new_description,
          folder_name: this.folder_name,
          folder_description: this.folder_description
        }
        if (!this.rule.id) {
          this.$emit('create-folder-save-rule', data)
        } else {
          this.$emit('create-folder-update-rule', data)
        }
      } else {
        const data = {
          name: this.new_name
        }
        if (!this.rule.id) {
          this.$emit('save-rule', data)
        } else {
          this.$emit('update-rule', data)
        }
      }
    }
  },
  computed: {
    ...mapGetters('rules', ['getRuleById', 'getError']),
    ...mapGetters('rulesFolders', {
      folderErrors: 'getError'
    }),
    ...mapGetters('rulesFolders', ['getAllFolders', 'isProcessing', 'getFolderById']),
    ...mapState('rules', ['data', 'processing', 'errors']),
    isErrors () {
      return this.createFolder
        ? (this.new_name.length === 0 || this.folder_name.length === 0)
        : this.new_name.length === 0
    },
    isDisabled () {
      return this.isErrors || this.processing || this.errors.length > 0
    }
  }
})
