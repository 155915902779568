

























































































































































import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import InfiniteLoading from 'vue-infinite-loading'
import SelectEntitiesItem from '@/components/strategies/modal/components/SelectEntitiesItem.vue'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import _ from 'lodash'
import {
  ENTITY_TYPE_AD,
  ENTITY_TYPE_ADSET,
  ENTITY_TYPE_CAMPAIGN,
  ENTITY_TYPE_EXTENSION,
  ENTITY_TYPE_KEYWORD,
  ENTITY_TYPE_SEARCH_TERM,
  GOOGLE_SERVICE,
  LINKEDIN_SERVICE,
  TWITTER_SERVICE
} from '@/constants/FbAutomatedRule'

export default Vue.extend({
  name: 'SelectEntitiesModal',
  props: {
    rule: {
      required: true,
      type: Object
    }
  },
  components: { VSelect, InfiniteLoading, SelectEntitiesItem },
  data () {
    return {
      select_all_campaigns: false,
      entityType: ENTITY_TYPE_CAMPAIGN,
      entityLevel: {
        code: ENTITY_TYPE_CAMPAIGN,
        label: 'Campaigns'
      }
    }
  },
  created () {
    this.resetState()
    if (this.rule && this.rule.entities) {
      if (this.rule.entity_type) {
        this.entityType = this.rule.entity_type
      } else {
        if (this.rule.entities.campaigns.length) {
          this.entityType = ENTITY_TYPE_CAMPAIGN
          this.setRuleEntityType({ id: this.rule.id, entityType: ENTITY_TYPE_CAMPAIGN, service: this.currentService })
        } else if (this.rule.entities.ad_sets.length) {
          this.entityType = ENTITY_TYPE_ADSET
          this.setRuleEntityType({ id: this.rule.id, entityType: ENTITY_TYPE_ADSET, service: this.currentService })
        } else if (this.rule.entities.ads.length) {
          this.entityType = ENTITY_TYPE_AD
          this.setRuleEntityType({ id: this.rule.id, entityType: ENTITY_TYPE_AD, service: this.currentService })
        } else if (this.currentService === GOOGLE_SERVICE) {
          if (this.rule.entities.extensions.length) {
            this.entityType = ENTITY_TYPE_EXTENSION
            this.setRuleEntityType({ id: this.rule.id, entityType: ENTITY_TYPE_EXTENSION, service: this.currentService })
          } else if (this.rule.entities.search_terms) {
            this.entityType = ENTITY_TYPE_SEARCH_TERM
            this.setRuleEntityType({ id: this.rule.id, entityType: ENTITY_TYPE_SEARCH_TERM, service: this.currentService })
          } else if (this.rule.entities.keywords) {
            this.entityType = ENTITY_TYPE_KEYWORD
            this.setRuleEntityType({
              id: this.rule.id,
              entityType: ENTITY_TYPE_KEYWORD,
              service: this.currentService
            })
          }
        }
      }
      this.selectEntity(this.entitiesOptions.find(opt => opt.code === this.entityType))
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_CAMPAIGN, ids: this.rule.entities.campaigns || [] })
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_ADSET, ids: this.rule.entities.ad_sets || [] })
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_AD, ids: this.rule.entities.ads || [] })
      if (this.currentService === GOOGLE_SERVICE) {
        this.setSelectedEntitiesPack({ level: ENTITY_TYPE_EXTENSION, ids: this.rule.entities.extensions || [] })
        this.setSelectedEntitiesPack({ level: ENTITY_TYPE_SEARCH_TERM, ids: this.rule.entities.search_terms || [] })
        this.setSelectedEntitiesPack({ level: ENTITY_TYPE_KEYWORD, ids: this.rule.entities.keywords || [] })
      }
    }
  },
  computed: {
    ...mapState({
      storeCampaigns: state => (state as any).campaigns.data,
      errors: state => (state as any).rules.errors
    }),

    ...mapGetters({
      campaignByAdSetExternalId: 'campaigns/campaignByAdSetExternalId',
      getByAdAccountId: 'campaigns/getByAdAccountId',
      adSetByAdExternalId: 'campaigns/adSetByAdExternalId',
      entitiesStatus: 'rules/entitiesStatus',
      entitiesType: 'rules/entitiesStatus',
      campaignsPage: 'campaigns/getCampaignsPage',
      campaignsLoaded: 'campaigns/loaded',
      searchCampaignName: 'campaigns/getSearchName',
      get_width: 'get_width',
      currentService: 'adService/getService'
    }),
    ...mapGetters('rules', ['getSelectedEntities']),
    entitiesOptions () {
      switch (this.currentService) {
        case LINKEDIN_SERVICE:
          return [
            {
              code: ENTITY_TYPE_CAMPAIGN,
              label: 'Campaign groups'
            },
            {
              code: ENTITY_TYPE_ADSET,
              label: 'Campaigns'
            },
            {
              code: ENTITY_TYPE_AD,
              label: 'Ads'
            }
          ]
        case TWITTER_SERVICE:
          return [
            {
              code: ENTITY_TYPE_CAMPAIGN,
              label: 'Campaigns'
            },
            {
              code: ENTITY_TYPE_ADSET,
              label: 'Ad Group'
            },
            {
              code: ENTITY_TYPE_AD,
              label: 'Ads'
            }
          ]
        case GOOGLE_SERVICE:
          return [
            {
              code: ENTITY_TYPE_CAMPAIGN,
              label: 'Campaigns'
            },
            {
              code: ENTITY_TYPE_ADSET,
              label: 'Ad sets'
            },
            {
              code: ENTITY_TYPE_EXTENSION,
              label: 'Extensions'
            },
            {
              code: ENTITY_TYPE_AD,
              label: 'Ads'
            },
            {
              code: ENTITY_TYPE_SEARCH_TERM,
              label: 'Search Terms'
            },
            {
              code: ENTITY_TYPE_KEYWORD,
              label: 'Keywords'
            }
          ]
        default:
          return [
            {
              code: ENTITY_TYPE_CAMPAIGN,
              label: 'Campaigns'
            },
            {
              code: ENTITY_TYPE_ADSET,
              label: 'Ad sets'
            },
            {
              code: ENTITY_TYPE_AD,
              label: 'Ads'
            }
          ]
      }
    },
    searchCampaign: {
      get () {
        return this.searchCampaignName
      },
      set (v) {
        this.setSearchName(v)
        this.filterCampaignsByName(v)
      }
    },
    campaigns () {
      if (this.rule && this.rule.ad_account_id) {
        return this.getByAdAccountId(this.rule.ad_account_id)
      } else return this.storeCampaigns
    },
    selectedCampaigns () {
      return this.getSelectedEntities.campaigns
    },
    selectedAdSets () {
      return this.getSelectedEntities.ad_sets
    },
    selectedAds () {
      return this.getSelectedEntities.ads
    },
    selectedExtensions () {
      return this.getSelectedEntities.extensions
    },
    selectedSearchTerms () {
      return this.getSelectedEntities.search_terms
    },
    selectedKeywords () {
      return this.getSelectedEntities.keywords
    },
    selectedEntities () {
      let s = ''
      const type = this.entitiesType(this.rule.id)

      if (this.rule.entities.all_active_campaigns) {
        return `All ${type} campaigns`
      }
      if (this.rule.entities.all_active_ad_sets) return `All ${type} ad sets`
      if (this.rule.entities.all_active_ads) return `All ${type} ads`
      const campaigns = this.selectedCampaigns.length
      if (campaigns > 0) {
        const ending =
          campaigns === 1 ? ' campaign, ' : ' campaigns, '
        s = 'All ads in ' + campaigns + ending
      }
      const ad_sets = this.selectedAdSets.length
      if (ad_sets > 0) {
        const ending =
          ad_sets === 1 ? ' ad set, ' : ' ad sets, '
        s += 'All ads in ' + ad_sets + ending
      }
      const ads = this.selectedAds.length
      if (ads && ads > 0) {
        const ending = ads === 1 ? ' ad, ' : ' ads, '
        s += ads + ending
      }
      const extensions = this.selectedExtensions.length
      if (extensions > 0) {
        const ending = extensions === 1 ? ' extension, ' : ' extensions, '
        s += extensions + ending
      }
      const search_terms = this.selectedSearchTerms.length
      if (search_terms > 0) {
        const ending = search_terms === 1 ? ' search term, ' : ' search terms, '
        s += search_terms + ending
      }
      const keywords = this.selectedKeywords.length
      if (keywords > 0) {
        const ending = keywords === 1 ? ' keyword, ' : ' keywords, '
        s += keywords + ending
      }
      return s ? _.trimEnd(s.trim(), ',') : 'Select specific items'
    }
  },

  methods: {
    ...mapMutations('rules', ['setRuleProp']),
    ...mapActions('rules', ['resetExecutionSpec', 'setSelectedEntitiesPack', 'clearSelectedEntities', 'setRuleEntityType']),
    ...mapActions('campaigns', ['loadRuleCampaigns', 'resetState', 'setSearchName']),
    filterCampaignsByName: _.debounce(function (name) {
      this.resetState().then(() => {
        // const filterArgs = [
        //   {
        //     field: 'CAMPAIGN_STATUS',
        //     predicate: null,
        //     value: 'ACTIVE, COMPLETED, ARCHIVED, PAUSED'
        //   }
        // ]
        // if (name) {
        //   filterArgs.push({
        //     field: 'NAME',
        //     predicate: null,
        //     value: name
        //   })
        // }
        // const filter = new Filter(filterArgs)
        let search: Record<string, any> = {
          'include[0]': 'ad_sets',
          'include[1]': 'ad_sets.ads',
          ad_account_id: this.rule.ad_account_id,
          page: this.campaignsPage
        }
        if (this.currentService === GOOGLE_SERVICE) {
          search = {
            ...search,
            'include[2]': 'extensions',
            'include[3]': 'ad_sets.search_terms',
            'include[4]': 'ad_sets.keywords'
          }
        }
        const params = {
          search: search,
          service: this.currentService
          // filter: filter
        }
        if (name && name !== '') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          params.search = { ...params.search, name: name }
        }
        this.loadRuleCampaigns(params)
      })
    }, 1000),
    // выбор в главном select
    selectEntity (v) {
      if (this.entityLevel && this.entityLevel.code !== v.code && this.rule.entity_type !== v.code) {
        this.resetExecutionSpec(this.rule.id)
      }
      this.entityLevel = v
      this.clearEntities()
      this.entityType = this.entityLevel.code
      this.setRuleEntityType({ id: this.rule.id, entityType: this.entityType, service: this.currentService })
    },

    getSelectedName (type) {
      const entities = this.getSelectedEntities[type]
      const ending = this.getEntitiesLabel(type.toLowerCase())
      return `${entities.length} ${
        entities.length > 1 ? ending : ending.slice(0, -1)
      }`
    },
    getEntitiesLabel (type: string): string {
      switch (type) {
        case 'campaigns':
          switch (this.currentService) {
            case LINKEDIN_SERVICE:
              return 'campaign groups'
            default:
              return type
          }
        case 'ad_sets':
          switch (this.currentService) {
            case LINKEDIN_SERVICE:
              return 'campaigns'
            default:
              return 'ad sets'
          }
        case 'search_terms':
          return 'search terms'
        default:
          return type
      }
    },

    close_modal () {
      this.searchCampaign = ''
      this.$attrs.on_close('select-entities')
    },

    save () {
      let value: Record<string, any> = {
        campaigns: this.selectedCampaigns,
        ad_sets: this.selectedAdSets,
        ads: this.selectedAds
      }
      if (this.currentService === GOOGLE_SERVICE) {
        value = {
          ...value,
          extensions: this.selectedExtensions,
          search_terms: this.selectedSearchTerms,
          keywords: this.selectedKeywords
        }
      }
      this.setRuleProp({
        id: this.rule.id,
        type: 'entities',
        value: value
      })

      if (
        this.errors['entities.ads'] &&
        (this.selectedCampaigns.length ||
          this.selectedAdsets.length ||
          this.selectedAds.length)
      ) {
        this.$store.dispatch('rules/removeError', 'entities.ads')
      }

      this.close_modal()
    },

    clearCampaigns () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_CAMPAIGN, ids: [] })
    },
    clearAdSets () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_ADSET, ids: [] })
    },
    clearExtensions () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_EXTENSION, ids: [] })
    },
    clearKeywords () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_KEYWORD, ids: [] })
    },
    clearAds () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_AD, ids: [] })
    },
    clearEntities () {
      this.clearSelectedEntities()
    },
    clearSearchTerms () {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_SEARCH_TERM, ids: [] })
    },
    infiniteHandler ($state) {
      if (this.campaignsLoaded) {
        $state.complete()
      } else {
        // const filterArgs = [
        //   {
        //     field: 'CAMPAIGN_STATUS',
        //     predicate: null,
        //     value: 'ACTIVE, COMPLETED, ARCHIVED, PAUSED'
        //   }
        // ]
        // if (this.searchCampaign) {
        //   filterArgs.push({
        //     field: 'NAME',
        //     predicate: null,
        //     value: this.searchCampaign
        //   })
        // }
        // const filter = new Filter(filterArgs)
        let search: Record<string, any> = {
          'include[0]': 'ad_sets',
          'include[1]': 'ad_sets.ads',
          ad_account_id: this.rule.ad_account_id,
          page: this.campaignsPage + 1
        }
        if (this.currentService === GOOGLE_SERVICE) {
          search = {
            ...search,
            'include[2]': 'extensions',
            'include[3]': 'ad_sets.search_terms',
            'include[4]': 'ad_sets.keywords'
          }
        }
        const params = {
          search: search,
          service: this.currentService
          // filter: filter
        }
        if (this.searchCampaign && this.searchCampaign !== '') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          params.search = { ...params.search, name: this.searchCampaign }
        }
        this.loadRuleCampaigns(params).then(res => {
          if (res) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
      }
    }
  }
})
