





























































































































































































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import {
  ENTITY_TYPE_CAMPAIGN,
  ENTITY_TYPE_ADSET,
  ENTITY_TYPE_AD,
  LINKEDIN_SERVICE,
  ENTITY_TYPE_EXTENSION,
  ENTITY_TYPE_SEARCH_TERM,
  ENTITY_TYPE_KEYWORD, EntityTypesList
} from '@/constants/FbAutomatedRule'
import BaseCheckbox from '@/components/BaseCheckbox.vue'

export default Vue.extend({
  name: 'SelectEntitiesItem',
  components: { BaseCheckbox },
  props: {
    campaign: {
      required: true,
      type: Object
    },
    entityType: {
      required: true,
      type: String
    },
    identifier: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      campaign_open: false,
      openAdSets: []
    }
  },
  computed: {
    ...mapGetters('rules', ['getSelectedEntities']),
    ...mapGetters({
      adSetIdByExternalId: 'campaigns/adSetIdByExternalId'
    }),
    ...mapGetters('adService', ['getService']),
    campaignLabel () {
      switch (this.getService) {
        case LINKEDIN_SERVICE:
          return 'Campaign Group'
        default:
          return 'Campaign'
      }
    },
    adSetLabel () {
      switch (this.getService) {
        case LINKEDIN_SERVICE:
          return 'Campaign'
        default:
          return 'Ad set'
      }
    },
    campaignArrowClass () {
      return this.campaign_open ? 'arrow-dwn expanded' : 'arrow-dwn'
    },
    campaignOpenClass () {
      return this.campaign_open ? 'opened' : 'closed'
    },
    campaignStatusIconClass () {
      return 'campaign-status-icon__' + (this.campaign.status ? this.campaign.status.toLowerCase() : '')
    },
    campaignSelected () {
      return this.getSelectedEntities.campaigns.includes(String(this.campaign.external_id))
    },
    selectionMethodCampaign () {
      return this.entityType === ENTITY_TYPE_CAMPAIGN
    },
    selectionMethodAdSet () {
      return this.entityType === ENTITY_TYPE_ADSET
    },
    selectionMethodAd () {
      return this.entityType === ENTITY_TYPE_AD
    },
    selectionMethodExtension () {
      return this.entityType === ENTITY_TYPE_EXTENSION
    },
    selectionMethodSearchTerm () {
      return this.entityType === ENTITY_TYPE_SEARCH_TERM
    },
    selectionMethodKeyword () {
      return this.entityType === ENTITY_TYPE_KEYWORD
    },
    campaignIndeterminate () {
      let selected_ad_sets = 0
      let selected_extensions = 0
      if (this.campaign.ad_sets.length > 0) {
        selected_ad_sets = this.campaign.ad_sets.filter(ad_set => this.adSetSelected(ad_set)).length
      }
      if (this.campaign.extensions?.length > 0) {
        selected_extensions = this.campaign.extensions.filter(extension => this.extensionSelected(extension)).length
      }
      return !this.campaignSelected && (
        (selected_ad_sets > 0) ||
        (selected_extensions > 0) ||
        this.campaign.ad_sets.some(ad_set => this.adSetIndeterminate(ad_set))
      )
    }
  },
  methods: {
    ...mapActions('rules', ['setEntityState', 'toggleSelectedEntity']),
    toggleCampaignOpen () {
      this.campaign_open = !this.campaign_open
    },
    adSetStatusIconClass (adSet) {
      return 'campaign-status-icon__' + (adSet.status ? adSet.status.toLowerCase() : '')
    },
    adStatusIconClass (ad) {
      return 'campaign-status-icon__' + (ad.status ? ad.status.toLowerCase() : '')
    },
    extensionLabel (extension) {
      return extension.field_type
    },
    filterChildEntities (type, id, status) {
      if (type === 'campaign') {
        if (status) {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_CAMPAIGN, id: String(this.campaign.external_id), add: true })
        } else {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_CAMPAIGN, id: String(this.campaign.external_id), add: false })
        }
      }
      if (type === 'adset') {
        if (status) {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_ADSET, id: String(id), add: true })
        } else {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_ADSET, id: String(id), add: false })
        }
      }
      if (type === 'ads' && this.entityType === ENTITY_TYPE_AD) {
        if (status) {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_AD, id: String(id), add: true })
        } else {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_AD, id: String(id), add: false })
        }
      }
      if (type === 'extension') {
        if (status) {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_EXTENSION, id: String(id), add: true })
        } else {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_EXTENSION, id: String(id), add: false })
        }
      }
      if (type === 'search_terms') {
        if (status) {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_SEARCH_TERM, id: String(id), add: true })
        } else {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_SEARCH_TERM, id: String(id), add: false })
        }
      }
      if (type === 'keywords') {
        if (status) {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_KEYWORD, id: String(id), add: true })
        } else {
          this.toggleSelectedEntity({ level: ENTITY_TYPE_KEYWORD, id: String(id), add: false })
        }
      }
    },
    adSetSelected (ad_set) {
      return this.getSelectedEntities.ad_sets.includes(String(ad_set.external_id))
    },
    extensionSelected (extension) {
      return this.getSelectedEntities.extensions.includes(String(extension.external_id))
    },
    adSelected (ad) {
      return this.getSelectedEntities.ads.includes(String(ad.external_id))
    },
    searchTermSelected (term) {
      return this.getSelectedEntities.search_terms.includes(String(term.external_id))
    },
    keywordSelected (keyword) {
      return this.getSelectedEntities.keywords.includes(String(keyword.external_id))
    },
    adsetArrowClass (a) {
      return this.adSetIsOpen(a) ? 'arrow-dwn expanded' : 'arrow-dwn'
    },
    toggleAdsetOpen (a) {
      if (this.adSetIsOpen(a)) {
        this.openAdSets = this.openAdSets.filter(i => i !== a.id)
      } else {
        this.openAdSets.push(a.id)
      }
    },
    adSetIsOpen (a) {
      return this.openAdSets.includes(a.id)
    },
    adSetIndeterminate (ad_set) {
      let selected_ads = 0
      let selected_search_terms = 0
      let selected_keywords = 0
      if (ad_set.ads.length) {
        selected_ads = ad_set.ads.filter(ad => this.adSelected(ad)).length
      }
      if (ad_set.search_terms?.length) {
        selected_search_terms = ad_set.search_terms.filter(term => this.searchTermSelected(term)).length
      }
      if (ad_set.keywords?.length) {
        selected_keywords = ad_set.keywords.filter(keyword => this.keywordSelected(keyword)).length
      }
      return !this.adSetSelected(ad_set) && (
        (selected_ads > 0) ||
        (selected_search_terms > 0) ||
        (selected_keywords > 0)
      )
    },
    checkboxAvailable (type: EntityTypesList): boolean {
      switch (type) {
        case ENTITY_TYPE_ADSET:
          return this.selectionMethodAdSet || this.selectionMethodAd || this.selectionMethodKeyword || this.selectionMethodSearchTerm
        case ENTITY_TYPE_AD:
          return this.selectionMethodAd
        case ENTITY_TYPE_KEYWORD:
          return this.selectionMethodKeyword
        case ENTITY_TYPE_SEARCH_TERM:
          return this.selectionMethodSearchTerm
        case ENTITY_TYPE_EXTENSION:
          return this.selectionMethodExtension
        default:
          return true
      }
    }
  }
})
