



























import Vue from 'vue'
import { SELECTION_FILTER_FIELD, SELECTION_FILTER_OPERATOR, IRuleSelectionFilter } from '@/models/Rules/IRule'
import SelectionFilter from '@/components/strategies/modal/components/SelectionFilter.vue'

export default Vue.extend({
  name: 'SelectionsFilters',
  props: {
    rule: {
      required: true
    },
    entity_type: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      filters: []
    }
  },
  components: { SelectionFilter },
  created () {
    if (this.rule.selection_filters && this.rule.selection_filters.length) {
      this.filters = this.rule.selection_filters.map(f => {
        return { ...f, uid: Date.now() + this.randomId(100) }
      })
    } else {
      this.filters = this.generateDefaultFilters()
      this.$emit('update_filters', this.filters)
    }
  },
  methods: {
    generateDefaultFilters () {
      return [
        {
          field: SELECTION_FILTER_FIELD.CAMPAIGN_NAME,
          operator: SELECTION_FILTER_OPERATOR.CONTAINS,
          value: '',
          uid: Date.now() + this.randomId(100)
        }
      ]
    },
    randomId (max: number) {
      return Math.floor(Math.random() * max)
    },
    updateFilter (filter: IRuleSelectionFilter) {
      const index = this.filters.findIndex(f => f.uid === filter.uid)
      if (index !== -1) {
        this.filters[index].field = filter.field
        this.filters[index].operator = filter.operator
        this.filters[index].value = filter.value
      }
      this.$emit('update_filters', this.filters)
    },
    addNewFilter () {
      this.filters.push(
        {
          field: SELECTION_FILTER_FIELD.CAMPAIGN_NAME,
          operator: SELECTION_FILTER_OPERATOR.CONTAINS,
          value: '',
          uid: Date.now() + this.randomId(100)
        }
      )
    },
    deleteFilter (uid) {
      this.filters = this.filters.filter(filter => filter.uid !== uid)
      this.$emit('update_filters', this.filters)
    }
  }
})
