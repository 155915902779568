






























import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters, mapState, mapActions } from 'vuex'
import _ from 'lodash'
import {
  ENTITY_TYPE_AD,
  ENTITY_TYPE_ADSET,
  ENTITY_TYPE_CAMPAIGN, ENTITY_TYPE_EXTENSION, ENTITY_TYPE_KEYWORD, ENTITY_TYPE_SEARCH_TERM,
  GOOGLE_SERVICE
} from '@/constants/FbAutomatedRule'
import SelectEntitiesFacebook from '@/components/strategies/modal/components/SelectEntitiesFacebook.vue'

export default Vue.extend({
  name: 'SelectSpecificItems',
  components: { InfiniteLoading, SelectEntitiesFacebook },
  props: {
    entityType: {
      type: String,
      default: null
    },
    rule: {
      required: true
    }
  },
  created () {
    this.resetState()
    if (this.rule && this.rule.entities) {
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_CAMPAIGN, ids: this.rule.entities.campaigns || [] })
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_ADSET, ids: this.rule.entities.ad_sets || [] })
      this.setSelectedEntitiesPack({ level: ENTITY_TYPE_AD, ids: this.rule.entities.ads || [] })
      if (this.currentService === GOOGLE_SERVICE) {
        this.setSelectedEntitiesPack({ level: ENTITY_TYPE_EXTENSION, ids: this.rule.entities.extensions || [] })
        this.setSelectedEntitiesPack({ level: ENTITY_TYPE_SEARCH_TERM, ids: this.rule.entities.search_terms || [] })
        this.setSelectedEntitiesPack({ level: ENTITY_TYPE_KEYWORD, ids: this.rule.entities.keywords || [] })
      }
    }
  },
  computed: {
    ...mapGetters({
      getByAdAccountId: 'campaigns/getByAdAccountId',
      campaignsPage: 'campaigns/getCampaignsPage',
      campaignsLoaded: 'campaigns/loaded',
      searchCampaignName: 'campaigns/getSearchName',
      currentService: 'adService/getService'
    }),
    ...mapState({
      storeCampaigns: state => (state as any).campaigns.data,
      errors: state => (state as any).rules.errors
    }),
    searchCampaign: {
      get () {
        return this.searchCampaignName
      },
      set (v) {
        this.setSearchName(v)
        this.filterCampaignsByName(v)
      }
    },
    campaigns () {
      if (this.rule && this.rule.ad_account_id) {
        return this.getByAdAccountId(this.rule.ad_account_id)
      } else return this.storeCampaigns
    }
  },
  methods: {
    ...mapActions('campaigns', ['loadRuleCampaigns', 'resetState', 'setSearchName']),
    ...mapActions('rules', ['setSelectedEntitiesPack', 'setRuleEntityType']),
    infiniteHandler ($state) {
      if (this.campaignsLoaded) {
        $state.complete()
      } else {
        // const filterArgs = [
        //   {
        //     field: 'CAMPAIGN_STATUS',
        //     predicate: null,
        //     value: 'ACTIVE, COMPLETED, ARCHIVED, PAUSED'
        //   }
        // ]
        // if (this.searchCampaign) {
        //   filterArgs.push({
        //     field: 'NAME',
        //     predicate: null,
        //     value: this.searchCampaign
        //   })
        // }
        // const filter = new Filter(filterArgs)
        let search: Record<string, any> = {
          'include[0]': 'ad_sets',
          'include[1]': 'ad_sets.ads',
          ad_account_id: this.rule.ad_account_id,
          page: this.campaignsPage + 1
        }
        if (this.currentService === GOOGLE_SERVICE) {
          search = {
            ...search,
            'include[2]': 'extensions',
            'include[3]': 'ad_sets.search_terms',
            'include[4]': 'ad_sets.keywords'
          }
        }
        const params = {
          search: search,
          service: this.currentService
          // filter: filter
        }
        if (this.searchCampaign && this.searchCampaign !== '') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          params.search = { ...params.search, name: this.searchCampaign }
        }
        this.loadRuleCampaigns(params).then(res => {
          if (res) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
      }
    },
    filterCampaignsByName: _.debounce(function (name) {
      this.resetState().then(() => {
        // const filterArgs = [
        //   {
        //     field: 'CAMPAIGN_STATUS',
        //     predicate: null,
        //     value: 'ACTIVE, COMPLETED, ARCHIVED, PAUSED'
        //   }
        // ]
        // if (name) {
        //   filterArgs.push({
        //     field: 'NAME',
        //     predicate: null,
        //     value: name
        //   })
        // }
        // const filter = new Filter(filterArgs)
        let search: Record<string, any> = {
          'include[0]': 'ad_sets',
          'include[1]': 'ad_sets.ads',
          ad_account_id: this.rule.ad_account_id,
          page: this.campaignsPage
        }
        if (this.currentService === GOOGLE_SERVICE) {
          search = {
            ...search,
            'include[2]': 'extensions',
            'include[3]': 'ad_sets.search_terms',
            'include[4]': 'ad_sets.keywords'
          }
        }
        const params = {
          search: search,
          service: this.currentService
          // filter: filter
        }
        if (name && name !== '') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          params.search = { ...params.search, name: name }
        }
        this.loadRuleCampaigns(params)
      })
    }, 1000)
  }
})
