import IAdAccount from '@/models/IAdAccount'
import IExecutionSpec from '@/models/Rules/IExecutionSpec'
import IScheduleSpec from '@/models/Rules/IScheduleSpec'
import INotificationSpec from '@/models/Rules/INotificationSpec'
import { SELECTION_METHODS } from '@/constants/FbAutomatedRule'

enum RuleEntityType {
  'AD' = 'AD',
  'ADSET' = 'ADSET',
  'CAMPAIGN' = 'CAMPAIGN'
}

export interface IRuleCondition {
  id?: string | number
  value?: string | number | string[] | number[]
  metric?: string
  custom_metric_id?: number | string
  type: 'group' | 'condition'
  conditions?: IRuleCondition[]
}

export enum SELECTION_FILTER_FIELD {
  CAMPAIGN_NAME = 'CAMPAIGN_NAME',
  AD_SET_NAME = 'AD_SET_NAME',
  AD_NAME = 'AD_NAME',
  CAMPAIGN_STATUS = 'CAMPAIGN_STATUS',
  AD_SET_STATUS = 'AD_SET_STATUS',
  AD_STATUS = 'AD_STATUS',
  CAMPAIGN_DELIVERY = 'CAMPAIGN_DELIVERY',
  AD_SET_DELIVERY = 'AD_SET_DELIVERY',
  AD_DELIVERY = 'AD_DELIVERY',
  CAMPAIGN_OBJECTIVE = 'CAMPAIGN_OBJECTIVE'
}

export enum SELECTION_FILTER_OPERATOR {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  IS = 'IS',
  IS_NOT = 'IS_NOT',
}

export interface IRuleSelectionFilter {
  field: `${SELECTION_FILTER_FIELD}`
  operator: `${SELECTION_FILTER_OPERATOR}`
  value: string
  uid?: number
}

export enum SELECTION_FILTER_CAMPAIGN_STATUS {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED'
}

export enum SELECTION_FILTER_ADSET_STATUS {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED'
}

export enum SELECTION_FILTER_AD_STATUS {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED'
}

export enum SELECTION_FILTER_CAMPAIGN_DELIVERY {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  NOT_DELIVERING = 'NOT_DELIVERING',
  REJECTED = 'REJECTED'
}

export enum SELECTION_FILTER_ADSET_DELIVERY {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  NOT_DELIVERING = 'NOT_DELIVERING'
}

export enum SELECTION_FILTER_AD_DELIVERY {
  ACTIVE = 'ACTIVE',
  PENDING_REVIEW = 'PENDING_REVIEW',
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  NOT_DELIVERING = 'NOT_DELIVERING',
  CAMPAIGN_PAUSED = 'CAMPAIGN_PAUSED',
  ADSET_PAUSED = 'ADSET_PAUSED',
  DISAPPROVED = 'DISAPPROVED',
  REJECTED = 'REJECTED'
}

export enum SELECTION_FILTER_CAMPAIGN_OBJECTIVE {
  CONVERSIONS = 'CONVERSIONS',
  REACH = 'REACH',
  APP_INSTALLS = 'APP_INSTALLS',
  LOCAL_AWARENESS = 'LOCAL_AWARENESS',
  CATALOG_SALES = 'CATALOG_SALES',
  ENGAGEMENTS = 'ENGAGEMENTS',
  VIDEO_VIEWS = 'VIDEO_VIEWS',
  LINK_CLICKS = 'LINK_CLICKS',
  OUTCOME_SALES = 'OUTCOME_SALES',
  POST_ENGAGEMENT = 'POST_ENGAGEMENT',
  PAGE_LIKES = 'PAGE_LIKES',
  OFFER_CLAIMS = 'OFFER_CLAIMS',
  MOBILE_APP_INSTALLS = 'MOBILE_APP_INSTALLS',
  MOBILE_APP_ENGAGEMENT = 'MOBILE_APP_ENGAGEMENT',
  MESSAGES = 'MESSAGES',
  OUTCOME_LEADS = 'OUTCOME_LEADS',
  LEAD_GENERATION = 'LEAD_GENERATION',
  EVENT_RESPONSES = 'EVENT_RESPONSES',
  STORE_TRAFFIC = 'STORE_VISITS',
  BRAND_AWARENESS = 'BRAND_AWARENESS',
  OUTCOME_AWARENESS = 'OUTCOME_AWARENESS',
  OUTCOME_ENGAGEMENT = 'OUTCOME_ENGAGEMENT',
  OUTCOME_APP_PROMOTION = 'OUTCOME_APP_PROMOTION',
  PRODUCT_CATALOG_SALES = 'PRODUCT_CATALOG_SALES',
  STORE_VISITS = 'STORE_VISITS'
}

export type IRuleEntity = Record<string, string[]>

export default interface IRule {
  id: number
  name: string
  description: string
  ad_account_id: number
  ad_account_external_id: string
  status: string
  ad_account: IAdAccount
  entities: IRuleEntity
  entity_type: RuleEntityType
  selection_method: `${SELECTION_METHODS}`
  selection_filters: IRuleSelectionFilter[]
  execution_spec: IExecutionSpec
  conditions?: IRuleCondition[]
  folder_id: number
  last_triggered: number
  next_state: string | null
  schedule_spec: IScheduleSpec
  notification_spec: INotificationSpec
  created_at: string | null
  updated_at: string | null
  user_id: number | null
}
