













































import Vue from 'vue'

import { mapState, mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'BaseEditableTile',
  data () {
    return {
      // result: 'Untitled Rule',
      editableState: false,
      error: false,
      errorText: ''
    }
  },
  props: {
    ruleName: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters('rules', ['getError']),
    ...mapState('rules', ['errors']),
    isError () {
      return !!this.getError('name')
    },
    result: {
      get () {
        return this.ruleName
      },
      set (v) {
        this.$emit('changeName', v)
      }
    }
  },
  watch: {
    isError (v) {
      if (v) {
        this.editableState = true
      }
    },
    ruleName () {
      if (this.getError('name')) {
        this.editableState = true
      }
      if (!this.getError('name') && this.$refs.resultField !== document.activeElement) {
        this.editableState = false
      }
    },
    result () {
      const check = this.result.length > 0
      if (check) {
        this.removeErr({ key: 'name' })
      } else {
        if (!this.errors.find(v => v.source.parameter === 'name')) {
          this.addError({
            title: 'Condition value error',
            detail: ['Incorrect name'],
            source: { parameter: 'name' }
          })
        }
      }
    }
  },
  methods: {
    ...mapActions('rules', ['addError', 'removeErr']),
    changeValue (event) {
      this.result = event.target.value
    },
    edit () {
      this.editableState = true
      this.$refs.resultField.focus()
    },
    blurInput () {
      if (this.result.length > 0) {
        this.editableState = false
      }
    },
    clickBack () {
      this.$emit('clickBack')
    }
  },
  mounted () {
    if (this.ruleName) {
      this.result = this.ruleName
    }
    if (this.getError('name')) {
      this.editableState = true
    }
  }
})
