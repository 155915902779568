import {
  ENTITY_TYPE_AD,
  ENTITY_TYPE_ADSET,
  ENTITY_TYPE_CAMPAIGN,
  LINKEDIN_SERVICE,
  SELECTION_METHODS
} from '@/constants/FbAutomatedRule'
import _ from 'lodash'
import store from '@/store'

export function getEntitiesLabel (type: string, currentService: string): string {
  switch (type) {
    case 'campaigns':
      switch (currentService) {
        case LINKEDIN_SERVICE:
          return 'campaign groups'
        default:
          return type
      }
    case 'ad_sets':
      switch (currentService) {
        case LINKEDIN_SERVICE:
          return 'campaigns'
        default:
          return 'ad sets'
      }
    case 'search_terms':
      return 'search terms'
    default:
      return type
  }
}

export function getEstimatedMatchCounts (selectionMethod: string, currentService: string) {
  const counts = []
  const estimatedMatch = store.getters['rules/getSelectionFiltersEstimatedMatch']
  switch (selectionMethod) {
    case SELECTION_METHODS.SPECIFIC_ITEMS:
      if (store.getters['rules/getSelectedEntities']['campaigns'].length) {
        counts.push(getSelectedName('campaigns', currentService))
      }
      if (store.getters['rules/getSelectedEntities']['ad_sets'].length) {
        counts.push(getSelectedName('ad_sets', currentService))
      }
      if (store.getters['rules/getSelectedEntities']['ads'].length) {
        counts.push(getSelectedName('ads', currentService))
      }
      break
    case SELECTION_METHODS.SELECTIONS_FILTER:
      if (estimatedMatch) {
        const keys = {
          [ENTITY_TYPE_CAMPAIGN]: 'campaigns',
          [ENTITY_TYPE_ADSET]: 'ad_sets',
          [ENTITY_TYPE_AD]: 'ads'
        }
        Object.keys(keys).forEach(key => {
          if (estimatedMatch[key] > 0) {
            const ending = getEntitiesLabel(keys[key].toLowerCase(), currentService)
            counts.push(`${estimatedMatch[key]} ${_.capitalize(ending)}`)
          }
        })
      }
      break
  }
  return counts.length ? counts.join(', ') : 'No results found'
}

export function getSelectedName (type: string, currentService: string) {
  const entities = store.getters['rules/getSelectedEntities'][type]
  const ending = getEntitiesLabel(type.toLowerCase(), currentService)
  return `${entities.length} ${
    entities.length > 1 ? _.capitalize(ending) : _.capitalize(ending.slice(0, -1))
  }`
}
