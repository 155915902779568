



























































import RuleNotificationSpec from '@/components/strategies/Rule/TaskWrapper/RuleNotificationSpec.vue'
import Vue from 'vue'
import SavingRuleModal from '../../components/strategies/modal/SavingRuleModal.vue'
import BasicParameters from '../../components/strategies/Rule/BasicParameters.vue'
import TaskWrapper from '../../components/strategies/Rule/TaskWrapper.vue'
import schedule from '../../components/strategies/Rule/TaskWrapper/Schedule.vue'
import Preloader from '../../components/BasePagePreloader.vue'
import EditableTitle from '../../components/strategies/Rule/EditableTitle.vue'
import { Filter } from '@/utils/Filter'

import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import {
  CONDITION_TYPE_CONDITION,
  CONDITION_TYPE_GROUP, FACEBOOK_SERVICE,
  GOOGLE_SERVICE,
  METRIC_TYPE_CUSTOM,
  SELECTION_METHODS
} from '@/constants/FbAutomatedRule'
import { IRuleCondition } from '@/models/Rules/IRule'
import ActiveRulesLimitReachedModal from '@/components/strategies/modal/ActiveRulesLimitReachedModal.vue'

export default Vue.extend({
  name: 'CreateRule',
  components: {
    RuleNotificationSpec,
    BasicParameters,
    TaskWrapper,
    schedule,
    SavingRuleModal,
    Preloader,
    EditableTitle
  },
  props: {
    ruleId: {
      required: false,
      default: null
    },
    service: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      errorMessage: null,
      isLoading: true,
      newRule: false,
      folder: null,
      blockClicks: true
    }
  },

  created () {
    this.resetState()
    this.setService(this.service)
    const promises = [
      this.loadAccounts({ search: { target: 'ads_management' } }),
      this.loadFolders({}),
      this.loadAllData({ requestType: 'rules' }),
      this.loadCustomMetrics({}).then(() => {
        this.blockClicks = false
      }),
      this.setErrors([]),
      this.setRuleEdited(false)
    ]
    if (this.ruleId) {
      promises.push(this.getRuleFromServer({ ruleId: this.ruleId })
        .then()
        .catch(() => {
          this.$router.push({ name: '404' })
        }))
    } else {
      promises.push(this.addNewRule())
      this.newRule = true
    }
    Promise.all(promises)
      .then(() => {
        this.folder = this.getAllFolders.data.filter(val => val.id === this.rule.id)[0]
        if (this.newRule) {
          this.setRuleProp({ id: this.rule.id, type: 'ad_account_id', value: this.ad_accounts[0]?.id })
        }
        if (this.currentService === GOOGLE_SERVICE) {
          this.loadConversionAction({ ad_account_id: this.rule.ad_account_id })
        }
        this.isLoading = false
      })
  },

  computed: {
    ...mapState('campaigns', {
      campaigns: 'data'
    }),
    ...mapState('rulesMeta', ['taskActions', 'metricsWithSchedule']),
    ...mapState('rules', ['ruleEdited', 'errors']),
    ...mapState('profile', {
      profile: state => state
    }),
    ...mapGetters({ ad_accounts: 'adAccounts/activeAccounts' }),
    ...mapGetters({ getError: 'rules/getError' }),
    ...mapGetters({ getAllFolders: 'rulesFolders/getAllFolders' }),
    ...mapGetters({ currentService: 'adService/getService' }),
    ruleEntitiesErrors () {
      let errObj = {}
      if (this.currentService !== FACEBOOK_SERVICE || this.rule.selection_method === SELECTION_METHODS.SPECIFIC_ITEMS) {
        errObj = {
          'entities.campaigns': !!this.rule.entities.campaigns.length,
          'entities.ads': !!this.rule.entities.ads.length,
          'entities.ad_sets': !!this.rule.entities.ad_sets.length,
          'entities.extensions': !!this.rule.entities.extensions?.length,
          'entities.search_terms': !!this.rule.entities.search_terms?.length,
          'entities.keywords': !!this.rule.entities.keywords?.length
        }
      } else {
        errObj = {
          selection_filters: !!this.rule.selection_filters.length
        }
      }
      for (const prop in errObj) {
        if (errObj[prop]) {
          return errObj[prop]
        }
      }
      return false
    },
    ruleConditionErrors () {
      const items = []
      const openGroup = (type, obj) => {
        return type === CONDITION_TYPE_GROUP ? Object.values(obj).map((v: IRuleCondition) => {
          items.push({
            id: v.id,
            value: v.value,
            metric: v.metric || v.custom_metric_id,
            type: v.type,
            conditions: openGroup(v.type, v.conditions)
          })
        }) : ''
      }
      Object.values(this.rule.conditions?.conditions)
        .map((v: IRuleCondition) => {
          if (v.type === CONDITION_TYPE_CONDITION) {
            items.push({
              id: v.id,
              value: v.value,
              metric: v.metric || v.custom_metric_id,
              type: v.type
            })
          }
          if (v.type === CONDITION_TYPE_GROUP) {
            openGroup(v.type, v.conditions)
          }
        })
      return items.filter(v => v.type !== CONDITION_TYPE_GROUP)
    },

    isScheduleAvailable () {
      let hasMetricsWithSchedule = false

      function checkGroupRecursive (obj, metrics) {
        for (const prop in obj) {
          if (hasMetricsWithSchedule === true || !metrics.length) break
          else if (obj[prop].type === CONDITION_TYPE_GROUP) {
            checkGroupRecursive(obj[prop].conditions, metrics)
          } else if (
            metrics.includes(obj[prop].metric) ||
            obj[prop].metric_type === METRIC_TYPE_CUSTOM
          ) {
            hasMetricsWithSchedule = true
          } else continue
        }
      }

      this.rule &&
      checkGroupRecursive(
        this.rule.conditions.conditions,
        this.metricsWithSchedule
      )
      return hasMetricsWithSchedule
    },
    rule () {
      return this.$store.getters['rules/getRuleById'](this.ruleId)
    }
  },

  methods: {
    ...mapActions('rulesMeta', [
      'loadAllData',
      'loadCustomMetrics'
    ]),
    ...mapActions('rulesFolders', [
      'creatingFolder',
      'loadFolders'
    ]),
    ...mapMutations('rulesFolders', [
      'openFolder'
    ]),
    ...mapMutations('rules', [
      'setRuleProp',
      'resetErrs',
      'removeErr'
    ]),
    ...mapActions('rules', [
      'addNewRule',
      'addError',
      'setErrors',
      'removeError',
      'saveRule',
      'updateRule',
      'getRuleFromServer',
      'setRuleEdited',
      'disableProcessing',
      'setNestedProp',
      'changeStatuses'
    ]),
    ...mapActions('campaigns', [
      'loadCampaigns',
      'resetState'
    ]),
    ...mapActions('googleConversionActions', ['loadConversionAction']),
    ...mapActions('adAccounts', [
      'loadAccounts'
    ]),
    ...mapActions('notifications', [
      'addNotification',
      'addExceptions'
    ]),
    ...mapActions('adService', ['setService']),
    goToRulesPage () {
      this.$router.push({ name: 'AutomatedRules', params: { service: this.service } })
    },
    setRuleStatus (status) {
      this.setRuleProp({
        id: this.rule.id,
        type: 'status',
        value: status
      })
    },
    checkValues () {
      // this.resetErrs()
      const notifications = []
      this.ruleConditionErrors.forEach(item => {
        if (item.value === null && !this.errors.find(v => v.source.parameter === 'conditions.' + item.id + '.value')) {
          this.addError({
            title: 'Condition value error',
            detail: ['In this property the value is required'],
            source: { parameter: 'conditions.' + item.id + '.value' }
          })
        }
        if (item.metric === null && !item.custom_metric_id && !this.errors.find(v => v.source.parameter === 'conditions.' + item.id + '.metric')) {
          this.addError({
            title: 'Condition metric error',
            detail: ['In this property the metric is required'],
            source: { parameter: 'conditions.' + item.id + '.metric' }
          })
        }
      })
      if (!this.ruleEntitiesErrors && !this.errors.find(v => v.source.parameter === 'entities.ads')) {
        this.addError({
          title: 'At least one item is required',
          detail: ['At least one item is required'],
          source: { parameter: 'entities.ads' }
        })
      }
      if (this.rule.schedule_spec.type === 'custom' && !this.rule.schedule_spec.schedule) {
        this.addError({
          title: 'Schedule is required',
          detail: ['Schedule is required. Please set the schedule to continue.'],
          source: { parameter: 'schedule_spec.schedule' }
        })
        notifications.push('Schedule is required. Please set the schedule to continue.')
      }
      if (!this.rule.ad_account_id) {
        this.addError({
          title: 'Ad account field is required',
          detail: ['Ad account field is required. Please select the ad account to continue.'],
          source: { parameter: 'ad_account_id' }
        })
      }
      // notification_spec
      const { notification_spec: n_spec } = this.rule
      if (
        n_spec && n_spec.enabled &&
        !n_spec.emails.length && !n_spec.slack_channels.length
      ) {
        ['notification_spec.emails', 'notification_spec.slack_channels']
          .forEach(parameter => this.addError({
            title: 'Notification channel required.',
            detail: ['Please select at least one notification channel.'],
            source: {
              parameter
            }
          }))
        notifications.push('Please select at least one notification channel.')
      }
      if (this.errors && this.errors.length === 0) {
        this.$modal.show('save-rule-modal')
      } else if (notifications.length === 0) {
        this.addNotification({
          type: 'danger',
          body: 'Please fill in all required fields'
        })
      } else {
        notifications.forEach(body => this.addNotification({
          type: 'danger',
          body
        }))
      }
    },
    saveNewRule (rule) {
      const callback = () => {
        this.$modal.hide('save-rule-modal')
        if (!this.rule.folder_id) {
          this.$router.push({ name: 'AutomatedRules', params: { service: this.service } })
        } else {
          this.openFolder(this.rule.folder_id)
          this.$router.push({
            name: 'AutomatedRules',
            params: {
              service: this.service,
              loadFolderCallback: () => {
                this.openFolder(this.rule.folder_id)
              }
            }
          })
        }
      }
      const status = rule.status
      if (status === 'ENABLED') {
        rule.status = 'DISABLED'
      }
      this.saveRule({ rule: rule })
        .then((response) => {
          if (status === 'ENABLED') {
            this.changeStatuses({ data: { ids: [response.id], status: status } })
              .catch(er => {
                if (er.response && er.response.data.errors) {
                  if (er.response.status === 402) {
                    this.$modal.show(ActiveRulesLimitReachedModal,
                      {},
                      {
                        name: 'active-rules-limit-reached-modal',
                        height: 'auto',
                        width: '600',
                        adaptive: true,
                        shiftY: 0.1,
                        overlayTransition: 'linear'
                      }
                    )
                  } else this.addExceptions(er.response.data)
                }
              })
              .finally(callback)
          } else {
            callback()
          }
        })
    },
    updateExistRule (rule) {
      this.updateRule({ rule: rule })
        .then(() => {
          this.$modal.hide('save-rule-modal')
          if (!this.rule.folder_id) {
            this.$router.push({ name: 'AutomatedRules', params: { service: this.service } })
          } else {
            this.$router.push({
              name: 'AutomatedRules',
              params: {
                service: this.service,
                loadFolderCallback: () => {
                  this.openFolder(this.rule.folder_id)
                }
              }
            })
          }
        })
    },
    createFolderSaveRule (event) {
      this.creatingFolder({ name: event.folder_name })
        .then((response) => {
          this.$modal.hide('create-folder-modal')
          this.setRuleProp({ id: this.rule.id, type: 'folder_id', value: response.folder_id })
          this.saveRule({ rule: this.rule })
            .then(() => {
              this.$modal.hide('save-rule-modal')
              this.$router.push({
                name: 'AutomatedRules',
                params: {
                  service: this.service,
                  loadFolderCallback: () => {
                    this.openFolder(response.folder_id)
                  }
                }
              })
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
          this.disableProcessing()
        })
    },
    createFolderUpdateRule (event) {
      this.creatingFolder({ name: event.folder_name })
        .then((response) => {
          this.$modal.hide('create-folder-modal')
          this.setRuleProp({ id: this.rule.id, type: 'folder_id', value: response.folder_id })
          this.updateRule({ rule: this.rule })
            .then(() => {
              this.$modal.hide('save-rule-modal')
              this.$router.push({
                name: 'AutomatedRules',
                params: {
                  service: this.service,
                  loadFolderCallback: () => {
                    this.openFolder(response.folder_id)
                  }
                }
              })
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
          this.disableProcessing()
        })
    },
    closeError () {
      this.setErrors([])
      this.errorMessage = null
    },
    selectAdAccount (account_id) {
      this.resetState()
      const filter = new Filter([{
        field: 'CAMPAIGN_STATUS',
        predicate: null,
        value: 'ACTIVE, COMPLETED, ARCHIVED, PAUSED'
      }])
      const campaignsParams = {
        search: { 'include[0]': 'ad_sets', 'include[1]': 'ad_sets.ads', ad_account_id: account_id },
        filter: filter
      }
      this.loadCampaigns(campaignsParams)
        .then(() => {
          this.loadConversionAction({ ad_account_id: account_id })
        })
    }
  }
})
