



















import DynamicModal from '../../DynamicModal.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'ActiveRulesLimitReachedModal',
  components: { DynamicModal },
  data () {
    return {

    }
  }
})
