































































import Vue from 'vue'

import SelectEntitiesModalFacebook from '../modal/SelectEntitiesModalFacebook.vue'
import SelectEntitiesModal from '../modal/SelectEntitiesModal.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import RuleDeleteModal from '@/components/strategies/modal/RuleDeleteModal.vue'
import * as AdAccountConstants from '@/constants/AdAccount'
import { getEstimatedMatchCounts } from '@/services/AutomatedRules/SelectedEntities'
import _ from 'lodash'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { LINKEDIN_SERVICE, FACEBOOK_SERVICE, SELECTION_METHODS } from '@/constants/FbAutomatedRule'

export default Vue.extend({
  name: 'BasicParameters',
  props: {
    rule: {
      required: true,
      type: Object
    }
  },
  components: {
    VSelect,
    RuleDeleteModal
  },
  data () {
    return {
      entities_options: [
        { label: 'Campaigns', code: 'campaigns' },
        { label: 'Ad Sets', code: 'ad_sets' },
        { label: 'Ads', code: 'ads' }
      ],
      selected_entities: null
    }
  },
  created () {
    this.clearSelectedEntities()
    if (this.rule.selection_method === SELECTION_METHODS.SELECTIONS_FILTER) {
      this.requestSelectionFiltersEstimatedMatch({ ad_account_id: this.rule.ad_account_id, entity_type: this.rule.entity_type, selection_filters: this.rule.selection_filters })
    }
  },
  computed: {
    ...mapGetters({
      entitiesType: 'rules/entitiesStatus',
      getError: 'rules/getError',
      getById: 'adAccounts/getById',
      currentService: 'adService/getService'
    }),
    availableAdAccounts () {
      return this.$store.state.adAccounts.data.filter(a => a.is_enabled && a.status === AdAccountConstants.STATUS_IMPORTED)
    },
    adAccountSelected () {
        return !!this.rule.ad_account_id
    }
  },
  methods: {
    ...mapMutations('rules', ['setRuleProp', 'removeErr', 'resetEntitiesMutation']),
    ...mapActions('rules', ['deleteRuleItem', 'resetEntities', 'clearSelectedEntities', 'requestSelectionFiltersEstimatedMatch']),
    checkRule () {
      this.$modal.show('save-rule-modal')
    },
    select_ad_account (account) {
      // eslint-disable-next-line eqeqeq
      if (this.rule.ad_account_id != account.id) {
        // this.resetEntities(this.rule.id)
        this.setRuleProp({ id: this.rule.id, type: 'ad_account_id', value: account.id })
        this.resetEntitiesMutation(this.rule.id)
        this.$emit('select-ad-account', account.id)
      }
    },
    checkRuleOpenModal () {
      this.$emit('check-rule-open-modal')
    },
    selectedEntities () {
      if (this.currentService === FACEBOOK_SERVICE && this.rule.selection_method === SELECTION_METHODS.SELECTIONS_FILTER) {
        return 'Estimated match: ' + getEstimatedMatchCounts(this.rule.selection_method, this.currentService)
      }
      let s = ''

      if (this.rule.entities.campaigns && this.rule.entities.campaigns.length > 0) {
        const ending = this.entitiesLabel('campaigns', this.rule.entities.campaigns.length)
        s = 'Selected ' + this.rule.entities.campaigns.length + ending
      }
      if (this.rule.entities.ad_sets && this.rule.entities.ad_sets.length > 0) {
        const ending = this.entitiesLabel('ad sets', this.rule.entities.ad_sets.length)
        s += 'Selected ' + this.rule.entities.ad_sets.length + ending
      }
      if (this.rule.entities.ads && this.rule.entities.ads.length > 0) {
        const ending = this.entitiesLabel('ads', this.rule.entities.ads.length)
        s += 'Selected ' + this.rule.entities.ads.length + ending
      }
      if (this.rule.entities.extensions && this.rule.entities.extensions.length > 0) {
        const ending = this.entitiesLabel('extensions', this.rule.entities.extensions.length)
        s += 'Selected ' + this.rule.entities.extensions.length + ending
      }
      if (this.rule.entities.search_terms && this.rule.entities.search_terms.length > 0) {
        const ending = this.entitiesLabel('search_terms', this.rule.entities.search_terms.length)
        s += 'Selected ' + this.rule.entities.search_terms.length + ending
      }
      if (this.rule.entities.keywords && this.rule.entities.keywords.length > 0) {
        const ending = this.entitiesLabel('keywords', this.rule.entities.keywords.length)
        s += 'Selected ' + this.rule.entities.keywords.length + ending
      }
      return s ? _.trimEnd(s.trim(), ',') : s
    },
    entitiesLabel (type: string, count: number): string {
      let label = type
      switch (type) {
        case 'campaigns':
          switch (this.currentService) {
            case LINKEDIN_SERVICE:
              label = 'campaign groups'
              break
            default:
              break
          }
          break
        case 'ad sets':
          switch (this.currentService) {
            case LINKEDIN_SERVICE:
              label = 'campaigns'
              break
            default:
              break
          }
          break
        default:
          break
      }
      return count === 1 ? ` ${label.slice(0, -1)}, ` : ` ${label}, `
    },
    showEntitiesModal () {
      this.removeErr({ key: 'entities', field: '' })
      if (this.currentService === FACEBOOK_SERVICE) {
        this.$modal.show(
          SelectEntitiesModalFacebook,
          {
            on_close: this.hide_modal,
            rule: this.rule
          },
          {
            name: 'select-entities',
            classes: 'SelectEntitiesModalFacebook',
            adaptive: true,
            width: 1000,
            height: 'auto',
            scrollable: true,
            overlayTransition: 'linear'
          }
        )
      } else {
        this.$modal.show(
          SelectEntitiesModal,
          {
            on_close: this.hide_modal,
            rule: this.rule
          },
          {
            name: 'select-entities',
            classes: 'SelectEntitiesModal',
            adaptive: true,
            width: 620,
            scrollable: true,
            height: 'auto',
            overlayTransition: 'linear'
          }
        )
      }
    },
    hide_modal (name) {
      this.$modal.hide(name)
    },
    deleteRule (id) {
      this.deleteRuleItem({ id: id }).then(() => {
        this.$modal.hide('delete-rule-modal')
        this.$router.push({ name: 'AutomatedRules' })
      })
    }
  }
})
