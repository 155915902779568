import { SELECTION_FILTER_FIELD } from '@/models/Rules/IRule'
import { ENTITY_TYPE_ADSET, ENTITY_TYPE_CAMPAIGN } from '@/constants/FbAutomatedRule'

export function fieldNotUsable (field: SELECTION_FILTER_FIELD, entityType: string): boolean {
  switch (field) {
    case SELECTION_FILTER_FIELD.CAMPAIGN_NAME:
    case SELECTION_FILTER_FIELD.CAMPAIGN_OBJECTIVE:
    case SELECTION_FILTER_FIELD.CAMPAIGN_DELIVERY:
    case SELECTION_FILTER_FIELD.CAMPAIGN_STATUS:
      return false
    case SELECTION_FILTER_FIELD.AD_SET_NAME:
    case SELECTION_FILTER_FIELD.AD_SET_STATUS:
    case SELECTION_FILTER_FIELD.AD_SET_DELIVERY:
      return entityType === ENTITY_TYPE_CAMPAIGN
    case SELECTION_FILTER_FIELD.AD_NAME:
    case SELECTION_FILTER_FIELD.AD_STATUS:
    case SELECTION_FILTER_FIELD.AD_DELIVERY:
      return entityType === ENTITY_TYPE_CAMPAIGN || entityType === ENTITY_TYPE_ADSET
    default:
      return false
  }
}
