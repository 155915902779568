




















































































































































































import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
// import Switcher from '@/components/Switcher.vue'
import NewUiSwitcher from '@/components/NewUIComponents/NewUiSwitcher.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import IntegrationService from '@/services/api/IntegrationService'
import Vue, { PropType } from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import SLACK_CHANNEL_ICON from '@/assets/icons/slack/slack-channel.svg'
import PRIVATE_SLACK_CHANNEL_ICON from '@/assets/icons/slack/slack-private-channel.svg'
import SLACK_ARCHIVED_CHANNEL_ICON from '@/assets/icons/slack/slack-archived-channel.svg'

type SlackOption = {
  label: string, // name or external_id
  value: string, // external_id
  icon: string
}

export default Vue.extend({
  name: 'RuleNotificationSpec',
  components: {
    BaseAlert,
    // Switcher,
    VSelect,
    NewUiSwitcher
  },
  props: {
    ruleId: {
      required: true,
      validator: prop => typeof prop === 'string' || prop === null
    },
    propEnabled: {
      type: Boolean,
      required: true
    },
    propEmailValue: {
      type: Array as PropType<Array<string>>,
      required: true
    },
    propSlackValue: {
      type: Array as PropType<Array<string>>,
      required: true
    }
  },
  data: () => ({
    enabled: false,
    emailUserDefined: [],
    emailValue: [],
    slackValue: [],
    slackChannels: undefined,
    slackSearch: '',
    preventSlackDropdownClosing: false
  }),
  watch: {
    enabled (newValue) {
      this.updateNotificationSpec(newValue, this.emailValue, this.slackValue)
    },
    emailValue (newValue) {
      this.updateNotificationSpec(this.enabled, newValue, this.slackValue)
    },
    slackValue (newValue) {
      this.updateNotificationSpec(this.enabled, this.emailValue, newValue)
    }
  },
  computed: {
    ...mapGetters('rules', ['getError']),
    ...mapGetters('profile', ['getEmail']),
    slackDropDownShouldOpen () {
      return ({ noDrop, open, mutableLoading }) => {
        return this.preventSlackDropdownClosing || (noDrop ? false : open && !mutableLoading)
      }
    },
    _emailOptions (): Array<string> {
      return [...this.emailUserDefined, this.getEmail]
    },
    emailOptions (): Array<string> {
      return this._emailOptions.map(_ => _).sort(
        (a, b) => {
          return (this.emailValue.includes(a) ? -1 : 1) + (this.emailValue.includes(b) ? 1 : -1)
        }
      )
    },
    _slackOptions (): Array<SlackOption> {
      return this.slackChannels && this.slackChannels.length
        ? this.slackChannels.map(c => ({
          label: c.attributes.name || c.attributes.external_id,
          value: c.attributes.external_id,
          icon: c.attributes.is_archived
            ? SLACK_ARCHIVED_CHANNEL_ICON
            : c.attributes.is_private
              ? PRIVATE_SLACK_CHANNEL_ICON
              : SLACK_CHANNEL_ICON
        }))
        : []
    },
    slackOptions (): Array<SlackOption> {
      return this._slackOptions.map(_ => _).sort(
        (a, b) => {
          return (this.slackValue.includes(a.value) ? -1 : 1) + (this.slackValue.includes(b.value) ? 1 : -1)
        }
      ).filter(channel => !this.slackSearch || channel.label.includes(this.slackSearch))
    },
    slackError (): boolean {
      return !!this.getError('notification_spec.slack_channels')
    },
    emailError (): boolean {
      return !!this.getError('notification_spec.emails')
    }
  },
  methods: {
    ...mapMutations('rules', ['setRuleProp', 'removeError']),
    slackInputSearchPointerDownHandler (e: PointerEvent): void {
      const target = e.target as HTMLInputElement | undefined

      if (target) {
        target.focus()
      }
    },
    slackClear (): void {
      this.slackValue = []
    },
    emailClear (): void {
      this.emailValue = []
    },
    emailToggle (email: string): void {
      this.emailValue.includes(email)
        ? this.emailValue = this.emailValue.filter(_email => _email !== email)
        : this.emailValue.push(email)
    },
    slackToggle (channel_id: string): void {
      this.slackValue.includes(channel_id)
        ? this.slackValue = this.slackValue.filter((_channel) => _channel !== channel_id)
        : this.slackValue.push(channel_id)
    },
    updateNotificationSpec (enabled: boolean, emails: Array<string>, channels: Array<string>) {
      const value = {
        enabled,
        emails: enabled ? emails : [],
        slack_channels: enabled ? channels : []
      }
      if (value.emails.length || value.slack_channels.length) {
        this.removeError('notification_spec.slack_channels')
        this.removeError('notification_spec.emails')
      }
      this.setRuleProp({
        id: this.ruleId,
        type: 'notification_spec',
        value
      })
    },
    toggleSwitcher (): void {
      this.enabled = !this.enabled
      this.removeError('notification_spec.slack_channels')
      this.removeError('notification_spec.emails')
    }
  },
  beforeMount (): void {
    this.enabled = this.propEnabled
    this.emailValue = this.propEmailValue
    this.slackValue = this.propSlackValue
    IntegrationService.get_slack_channels()
      .then((response) => {
        if (response?.data?.data || response?.data?.data === null) {
          this.slackChannels = response.data.data
            .filter(({ attributes }) => attributes?.is_channel && !attributes.is_archived)
        }
      })
      .finally(() => {
        if (this.slackChannels === undefined) {
          this.slackChannels = null
        }
      })
  }
})
